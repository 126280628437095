// Colors
$white: #ffffff;
$black: #000000;
$gold: #F2CB6C;
$gold-dark: #F7C54D;
$green: #174543;
$green-light: #42BFBD;

// Sizes
$base-font-size: 10px;

$breakpoints: (
  "phone"       : 400px,
  "phone-wide"  : 480px,
  "phablet"     : 560px,
  "tablet-small": 640px,
  "tablet"      : 768px,
  "tablet-wide" : 1024px,
  "desktop"     : 1248px,
  "desktop-wide": 1440px
);

$content-size: 1210px;

//media
$lg:        1440px;
$pc:        1280px;
$sm-pc:     1024px;
$tablet:    968px;
$sm-tablet: 768px;
$lg-mob:    600px;
$mob:       480px;
$i5:        320px;

// transitions
$t2: .2s linear;
$t3: .3s linear;

