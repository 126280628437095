.btn {
  padding: 1.6rem 4.2rem;
  text-transform: uppercase;
  transition: $t3;
  display: inline-block;
  transform: scale(.95);
  &:hover {
    transform: none;
  }

  &-gold {
    background-color: $gold;
    color: black;
    @include maxw($sm-tablet) {
      padding: 1rem 2rem;
    }
    &:hover {
      background-color: $gold-dark;
    }
  }

  &-black {
    background-color: black;
    color: white;
    &:hover {
      color: $gold;
    }
  }
}