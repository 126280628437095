.headline {
  &-2 {
    font-family: 'FNSB', sans-serif;
    display: inline-flex;
    font-size: 3.2rem;
    line-height: 4.2rem;
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    text-align: center; 
    &.centred {
      display: flex;
      justify-content: center;
      margin-bottom: 4rem;
    }
  }

  &-4 {
    font-size: 2.4rem;
    text-transform: uppercase;
    font-family: 'FNSB', sans-serif;
    margin-bottom: 1rem;
  }
}

p {
  font-size: 2rem;
  line-height: 2.4rem;
  font-family: 'FNL', sans-serif;
  @include maxw($mob) {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}