.pop {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 20;

  &-inner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: black;
    overflow: auto;
  }

  &-cont {
    padding: 5rem 7.5rem 5rem 10rem;
    background-color: $green;
    @include maxw( $sm-tablet ) {
      padding: 5rem 2rem 5rem 4rem;
    }
    
  }

    &-close {
      z-index: 3;
      position: absolute;
      width: 5rem;
      height: 5rem;
      top: 0;
      right: 0;
      transform: translate(50%, -50%);
      box-shadow: 0 .2rem .4rem rgba(black, 0.5);
      background-color: $green; 
      cursor: pointer;
      &:hover {
        &::before, &::after {
          background-color: $gold;
        }
      }

      &::before, &::after {
        @extend .absPseudo;
        width: 2rem;
        height: 0.3rem;
        background-color: white;
        top: 50%;
        left: 50%;
        transition: $t2;
      }     
      &::before {
        transform: translate(-50%, -50%) rotate(45deg);
      }
      &::after {
        transform: translate(-50%, -50%) rotate(-45deg);
      }
    }

  &-jury {
    display: flex;
    flex-direction: column;
    max-width: 60rem;
    // overflow: hidden;
    @include maxw( $sm-tablet ) {
      max-width: calc(100% - 6rem);
    }
    @include maxw( $lg-mob ) {
      top: 3rem;
      position: absolute;
    }

    &-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      img {
        position: absolute;
        right: 3rem;
        top: 3rem;        
      } 
    }

    &__top {
      display: flex;
      align-items: center;
      @include maxw( $lg-mob ) {
        flex-direction: column;
      }

      &-img {
        // width: 75%;
        // padding-bottom: 50%;
        @include maxw( $sm-tablet ) {
          width: 50%;
        }
        @include maxw( $lg-mob ) {
          padding-bottom: 60%;
          margin-bottom: 2rem;
        }
        img, picture {
          // position: absolute;
          @extend .img-cover;
        }
      }

      &-title {
        font-size: 4rem;
        line-height: 4.2rem;
        font-family: 'FNL', sans-serif;
        margin-left: 3rem;
        @include maxw( $lg-mob ) {
          margin-left: 0;
          text-align: center;
        }
      }
    }
    &__bot {
      margin-top: 2.5rem;
    }
  }
}