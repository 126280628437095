@mixin size($a,$b: $a) {
  width: $a;
  height: $b;
}

@mixin placeholder {
  ::-webkit-input-placeholder {@content}
  :-moz-placeholder           {@content}
  ::-moz-placeholder          {@content}
  :-ms-input-placeholder      {@content}
}


@mixin maxw($media) {
  @media only screen and(max-width: $media) {
      @content;
  }
}

@mixin minw($media) {
  @media only screen and(min-width: $media) {
      @content;
  }
}
@mixin minh($media) {
  @media only screen and(min-height: $media) {
      @content;
      }
}
@mixin maxh($media) {
  @media only screen and(max-height: $media) {
      @content;
      }
}



.dfcol {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.absPseudo {
  content: "";
  display: block;
  position: absolute;
}

.absVertAlign {
  top: 50%;
  transform: translateY(-50%);
}

.absCenterPos {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.flex-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.icon-bg {
  background-repeat: no-repeat;
  background-position: center center;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.absCentred {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
