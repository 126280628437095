.banner {
  z-index: 1;
  background-color: black;
  overflow: hidden;
  @include maxw( $lg-mob ) {
    padding-bottom: 1rem;
  }
  &-bg {
    width: 100%;
    height: 100%;
    @include maxw( $lg-mob ) {
      width: 145%;
      height: auto;
    }
    img {
      @extend .img-cover;
    }
  }

  &-cont {
    position: absolute;
    left: 57.7%;
    bottom: 20%;
    @include maxw( $lg-mob ) {
      left: auto;
      bottom: auto;
      position: relative;
      padding: 0 2rem;
    }

    &-title {
      font-family: 'YesevaOne-Regular', sans-serif;
      font-size: 7rem;
      line-height: 6rem;
      color: $gold;
      margin-bottom: 5rem;
      max-width: 50rem;
      font-weight: normal;
      @include maxw( $pc ) {
        font-size: 6rem;
      }
      @include maxw($sm-pc) {
        font-size: 4rem;
        line-height: 4rem;
        max-width: 35rem;
      }
      @include maxw($sm-tablet) {
        @include minw( $lg-mob ) {
          font-size: 3rem;
          line-height: 3rem;
          margin-bottom: 3rem;
          max-width: 15rem;          
        }
      }

    }

    // .btn {
    //   @include maxw( $lg-mob ) {
    //     left: 50%;
    //     transform: translateX(-50%);
    //   }
    // }
  }

  // .btn {
  //   position: absolute;
  //   left: 57.7%;
  //   bottom: 17%;
  //   @include maxw($pc) {
  //     bottom: 14%;
  //   }
  // }
}

.liga-quote {
  display: flex;
  padding: 10rem 0;

  .parallax-el {
    right: 17.7%;
    top: 0;
  }
  
}

.quote {

  h1 {
    text-align: left;
  }

  span {
    font-size: 3.6rem;
    line-height: 4.2rem;
    font-family: 'FNL', sans-serif;
    @include maxw($sm-tablet) {
      font-size: 2.6rem;
      line-height: 3.2rem;
    }
  }

  &-small {
    position: relative;
    font-size: 1.4rem;
    margin-top: 6rem;
    &::before {
      content: '*';
      display: block;
      position: absolute;
      left: -1.5rem;
      color: #42BFBD;
    }
  }
}

.info-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  @include maxw($sm-tablet) {
    flex-direction: column;
  }

  &-section {
    .parallax-el.tringle {
      right: 0;
      bottom: 0;
    }
    .parallax-el.z {
      left: 0;
      bottom: 0;
    }
  }
  
  &__el {
    width: 45%;
    display: flex;
    align-items: flex-start;
    padding: 3.5rem 0;
    @include maxw($sm-tablet) {
      width: 100%;
      padding: 1.5rem 0;
    }
    &:nth-child(even) {
      @include minw($sm-tablet) {
        margin-left: 10%;       
      } 
    }

    img {
      margin-right: 3rem;
    }
    span {
      font-family: 'FNSB', sans-serif;
    }
  }
}

.crono {
  display: flex;
  flex-direction: column;

  &-section {
    .parallax-el {
      right: -7rem;
      bottom: -7rem;
    }
  }

  &__el {
    display: flex;
    align-items: center;
    @include maxw($sm-tablet) {
      flex-direction: column;
      padding-left: 7rem;
    }
    &:not(:last-child) {
      padding-bottom: 7.5rem;
      @include maxw($sm-tablet) {
        padding-bottom: 3rem;
      }
      // .crono__el-num {
        &::after {
          @extend .absPseudo;
          left: 17.7rem;          
          top: 3rem; 
          width: 0.2rem;
          height: 100%;
          background-color:#42BFBD;
          opacity: .3;
          @include maxw($sm-tablet) {
            // right: auto;
            left: 3rem;
            // display: none;
          }
        }
      // } 
    }

    &-num {
      width: 35%;
      font-family: 'FNEB', sans-serif;
      font-size: 9rem;
      line-height: 10.5rem;
      letter-spacing: 0.36rem;
      color: $green;
      position: relative;
      @include maxw($sm-tablet) {
        width: 100%;
      }

      &::before {
        @extend .absPseudo;
        right: 4.5rem;
        top: 3rem;
        width: 1rem;
        height: 1rem;
        border: .2rem solid $gold;
        background-color: $green;
        border-radius: 100%;
        z-index: 2;
        @include maxw($sm-tablet) {
          right: auto;
          left: -4.4rem;
        }
      }
    }
    
    &-info {
      // flex-grow: 1;
      width: 65%;
      @include maxw($sm-tablet) {
        width: 100%;
      }
    }
      &-date {
        text-transform: uppercase;
        color: $gold;
        font-family: 'FNSB', sans-serif;
        margin-bottom: 0.6rem;
      }
  }
}


.nomin {

  &-block {
    &:not(:last-child) {
      margin-bottom: 3.5rem;
    }
    &-title {
      text-transform: uppercase;
      font-size: 1.6rem;
    }
  }

  &-title {
    font-size: 2.4rem;
    font-family: 'FNL', sans-serif;
    font-weight: normal;
    text-transform: uppercase;
    color: $green-light;
    margin-bottom: 3rem;
    letter-spacing: 0.1rem;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;

    &__el {
      width: 33%;
      padding-left: 4rem;
      margin-bottom: 5rem;
      display: flex;
      align-items: flex-start;
      font-family: 'FNL', sans-serif;

      @include maxw($tablet) {
        width: 48%;
      }
      @include maxw($lg-mob) {
        width: 100%;
        padding-left: 0;
        margin-bottom: 2rem;
      }

      &::before {
        content: '';
        display: block;
        background-image: url('../images/general/liga-logo-simple.svg');
        width: 4rem;
        height: 3.5rem;
        flex-shrink: 0;
        margin-right: 1.5rem;
        margin-top: -0.5rem;
      }
    }
  }
}


.jury {
  &-slider {
    margin: 0 7rem 0 5rem;
    padding: 0 .5rem;
    opacity:0;
    @include maxw($pc) {
      margin: 0 9rem 0 7rem;
    }
    @include maxw( $lg-mob ) {
      margin: 0 5rem;
    }
    &__el {
      cursor: pointer;
      &:hover {
        .jury-slider__el-img {
          box-shadow: 0px .4rem 1rem #755300;
          &::after {
            background-color: $gold;
          }
        }
      }

      &-img {
        position: relative;
        padding-bottom: 120%;
        
        img, picture {
          position: absolute;
          @extend .img-cover;
          z-index: 2;
        }
        &::after {
          @extend .absPseudo;
          width: 100%;
          height: 100%;
          background-color: $green;
          z-index: 1;
          top: 0.5rem;
          left: 0.5rem;
          transition: $t3;
        }
      }

      &-title {
        margin-top: 3rem;
        max-width: 5rem;
      }

      &-about {
        display: none;
      }
    }
  }

  &-pag {
    position: absolute;
    left: -1rem;
    // @extend .absVertAlign;
    display: flex;
    align-items: flex-end;
    bottom: 30%;
    font-size: 2rem;
    color: rgba(white, .7);
    @include maxw($pc) {
      left: 1rem;
    }
    @include maxw( $sm-tablet ) {
      left: -6rem;
      transform: scale(.8);
    }
    @include maxw( $lg-mob ) {
      left: -4.5rem;
    }
    @include maxw( $mob ) {
      left: -3rem;
    }
  
    .swiper-pagination-current {
      font-size: 4rem;
      margin-right: -0.5rem;
      color: white;
    }
    .swiper-pagination-total {
      margin-left: 0.5rem;
    }
    
  }

  &-button-next, &-button-prev {
    width: 5rem;
    height: 5rem;
    border-radius: 100%;
    background-color: $green;
    outline: none;
    right: 0;
    position: absolute;
    bottom: 33%;
    cursor: pointer;
    transition: $t3;
    @include maxw($pc) {
      right: 2rem;
    }
    @include maxw( $lg-mob ) {
      width: 3rem;
      height: 3rem;
    }
    &:hover {
      background-color: $gold;
    }
    &::before {
      @extend .absPseudo;
      @extend .absVertAlign;
      background-image: url('../images/icons/arrow.svg');
      right: 1.2rem;
      width: 6.5rem;
      height: 1rem;
      @include maxw( $lg-mob ) {
        width: 3rem;
        background-position: center right;
      }
    }
  }
  &-button-next {
    transform: translateY(-4rem);
  }
  &-button-prev {
    transform: translateY(2rem) rotateY(180deg) translateX(50%);
  }
}

.metod {
  &-section {
    padding: 7rem 0;
    @include maxw( $lg-mob ) {
      padding: 3rem 0;
    }
    &-bg {
      position: absolute;
      top: 0;
    
      @extend .img-cover;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  @include maxw( $lg-mob ) {
    flex-direction: column;
    align-items: flex-start;
  }

  .btn {
    @include maxw( $lg-mob ) {
      margin-top: 3rem;
    }
  }
}
