@font-face {
  font-family: "FNR";
  src: url("../fonts/FTN45.OTF");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "FNSB";
  src: url("../fonts/FTN65.OTF");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "FNEB";
  src: url("../fonts/FTN95.OTF");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "FNL";
  src: url("../fonts/FTN35.OTF");
  font-style: normal;
  font-weight: normal;
}
@font-face {
  font-family: "YesevaOne-Regular";
  src: url("../fonts/YesevaOne-Regular.ttf");
  font-style: normal;
  font-weight: normal;
}