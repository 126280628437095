.footer { 
  overflow: hidden;
  &-cont {
    display: flex;
    padding: 5.5rem 0 3.5rem;
    @include maxw($tablet) {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__el {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      @include maxw($tablet) {
        width: 46%;
        flex-grow: 0;
        margin-bottom: 3rem;
      }
      @include maxw($lg-mob) {
        width: 100%;
      }
      &:not(:last-child) {
        margin-right: 3rem;
        @include maxw($tablet) {
          margin-right: 0;
        }
      }
      &:first-child {
        span {
          opacity: .8;
          &:last-child {
            margin-top: auto;
          }
        }
      }

      &-person, &-email, &-tel {
        display: flex;
        align-items: center;
        &::before {
          content: '';
          display: flex;
          width: 1.6rem;
          height: 1.6rem;
          background-repeat: no-repeat;
          margin-right: 1.3rem;

        }
      }

      &-person {
        &::before {
          background-image: url('../images/icons/person.svg');
        }
      }
      &-email {
        &::before {
          background-image: url('../images/icons/mail.svg');
        }
      }
      &-tel {
        &::before {
          background-image: url('../images/icons/tel.svg');
        }
      }

      span, a {
        font-size: 1.6rem;
        line-height: 1.9rem;
        margin-bottom: 1rem;
        color: white;
        font-family: 'FNL', sans-serif;
      }

      a {
        transition: $t2;
        &:hover {
          color: $gold;
        }
      }

      &-title {
        text-transform: uppercase;
        font-size: 1.6rem;
        margin-bottom: 2.2rem;
      }

      .headline-4 {
        @include maxw($tablet) {
          margin-top: -0.5rem;
        }
      }
    }
  }  

  &-bot {
    position: relative;
    padding: 2rem 0;
    &::before {
      @extend .absPseudo;
      left: calc((-100vw + 1210px)/ 2 - 2rem);
      width: 100vw;
      height: 0.1rem;
      top: 0;
      background-color: #D8D8D8;
      opacity: .15;
      @include maxw($pc) {
        left: -2rem;
      }
    }
    p {
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-family: 'FNL', sans-serif;
    }
  }
}