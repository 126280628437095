.header {
  padding: 3rem 0;
  position: fixed;
  width: 100%;
  z-index: 2;
  max-width: 100vw;

  @include maxw($tablet) {
    padding: 1.5rem 0;
  }

  &::after {
    opacity: 0;
    @extend .absPseudo;
    width: 100%;
    height: 120%;
    transition: .5s ease-in-out;
    left: 0;
    top: 0;
    z-index: -1;

    background-image:
    linear-gradient(
      to bottom,
      black 50%,
      rgba(black, .7) 80%,
      transparent
    );

    @include maxw($tablet) {
      opacity: 1;
    }
  }

  &.scrolled {
    &::after {
      opacity: 1;
    }
  }

  .container {
    display: flex;
    align-items: center;
  }    

  &-nav {
    margin-left: auto;
    margin-right: 3rem;
    display: flex;
    @include maxw($sm-tablet) {
      display: none;
    }
    a {
      color: $gold;
      text-transform: uppercase;
      font-size: 1.6rem;
      text-align: center;
      transition: .2s linear;

      &:not(:last-child) {
        margin-right: 2rem;
      }
      &::after {
        display: block;
        content: attr(title);
        font-family: 'FNSB', sans-serif;
        height: 0;
        overflow: hidden;
        visibility: hidden;
      }

      &:hover {
        font-family: 'FNSB', sans-serif;
      }
    }
  }

  &-icons {
    opacity: .8;
    transition: .2s linear;
    @include maxw($sm-tablet) {
      display: none;
    }
    &:hover {
      opacity: 1;
    }
  }


  &-menu-btn {
    @include minw($sm-tablet) {
      display: none;
    }

    width: 3rem;
    height: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: auto;

    span {
      height: 0.1rem;
      width: 3rem;
      background-color: $gold;
    }
  }
}


.full-menu {
  @extend .pop;

  &-inner {
    background-color: rgba(black, .9) !important;
    @extend .pop-inner;
  }
  
  &-cont {
    padding: 3rem;
    @extend .dfcol;
  }



  &__el {
    font-family: 'FNL', sans-serif;
    font-size: 2rem;
    line-height: 2.4;
    color: $gold;
    text-transform: uppercase;
  }
}