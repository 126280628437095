@import "utils/libs";
@import "utils/vars";
@import "utils/mixins";
@import "utils/fonts";

html {
  font-size: $base-font-size;
  height: 100%;
}

a {
  text-decoration: none;
}

body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: black;
  color: white;
  font-family: 'FNR', sans-serif;
  overflow-x: hidden;
  max-width: 100%;
}

*,
*::before,
*::after { box-sizing: border-box; }

ul[class],
ol[class] {
  list-style: none;
  padding: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd,
div {
  margin: 0;
  position: relative; 
}

a:not([class]) { text-decoration-skip-ink: auto; }

img {
  max-width: 100%;
  display: block;
}

input,
button,
textarea,
select { font: inherit; }

/* Удаляем все анимации и переходы для людей, которые предпочитай их не использовать */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

audio,
canvas,
iframe,
img,
svg,
video,
textarea { vertical-align: middle; }

textarea { resize: none; }

section { position: relative; }

input,
select,
button { outline: none; }

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content { flex: 1 0 auto; }

main {
  font-size: 1.6rem;
  overflow: hidden;
  @include maxw($tablet) {
    margin-top: 7rem;
  }
}


.container {
  margin: 0 auto;
  width: 100%;
  padding: 0 2rem;
  position: relative;

  &-big {
    max-width: $content-size;
  }
  &-middle {
    max-width: 968px;
  }
  &-small {
    max-width: 690px; 
  }
}

.section {
  padding-bottom: 15rem;
  @include maxw($sm-tablet) {
    padding-bottom: 7rem;
  }
}

.parallax-el {
  position: absolute;
  @include maxw($lg-mob) {
    display: none;
  }
  &.static {
    transform: none !important;
  }

  img {
    @extend .img-contain;
  }

}

// Модули
@import "modules/modules";

// Pages
@import 'pages/home'; 
